@import "../../_globalColor";

.contact-div-main {
  display: flex;
  justify-content: space-between; /* This will push the children to the edges of the container. */
  align-items: flex-start; /* This will vertically center the children. */
}

.dark-mode a {
  color: $textColorDark;
}

.contact-title {
  font-size: 65px;
  font-weight: 400;
}

.contact-detail,
.contact-detail-email {
  margin-top: 20px;
  font-size: 40px;
  font-weight: 400;
  color: $subTitle;
  text-decoration: none;
}

.contact-detail:hover,
.contact-detail-email:hover {
  color: $textColor;
  text-shadow: 2px 1px 2px $contactDetailHoverTS;
  transition: all 0.3s;
}

.contact-subtitle {
  text-transform: uppercase;
}

.contact-text-div {
  margin-top: 1.5rem;
}

.contact-margin-top {
  margin-top: 4rem;
}

.contact-image-div > img {
  max-width: 85%;
  height: auto;
  margin-left: 1.5rem;
  transform: translateX(-100px);
  //margin-top: -4rem;
}

/* Media Query */
@media (max-width: 1380px), (max-width: 768px) {
  .contact-title {
    font-size: 56px;
    font-weight: 400;
    text-align: center;
  }

  .contact-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }

  .contact-text-div {
    text-align: center;
    margin-top: 1.5rem;
  }

  .contact-detail,
  .contact-detail-email {
    font-size: 20px;
  }

  .contact-image-div {
    display: none;
  }
}
